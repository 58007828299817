<template>
  <div>
    <v-alert v-if="success" border="bottom" color="success" dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error" border="bottom" color="red" dark>
      <b>Ocurrió un error al guardar el Parametro de la operatoria.</b>
      {{ errorMensaje }}
    </v-alert>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="4" md="4">
          <v-select
            v-model="tipo_servicio_id"
            :items="tipoServicios"
            required
            @change="presentarServicios"
            label="Categoria de Operatorias"
          ></v-select>
        </v-col>
        <v-col cols="4" md="4">
          <v-select
            v-model="operatoria_id"
            :items="operatorias"
            @change="presentarParametros"
            required
            label="Operatoria"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <TablaParametros
            v-if="!!operatoria_id && !!tipo_servicio_id"
            :tipo_parametro="tipo_servicio_id"
            :key="componentKey"
            :lineaCredito_id="operatoria_id"
            :userId="userId"
            x-large
          ></TablaParametros>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import TablaParametros from '@/components/TablaParametros.vue';
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  data: () => ({
    componentKey: 0,
    loading: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
    operatoria_id: '',
    userId: null,
    tipo_servicio_id: '',
    tipoServicios: [],
    operatorias: [],
    tipoParametros: [],
    parametrosMaestros: [],
    parametrosCargados: true,
    valid: false,
    email: '',
    valor: '',
    password: '',
    nameRules: [(v) => !!v || 'El campo nombre es obligatorio'],
    emailRules: [
      (v) => !!v || 'El correo electronico es obligatorio',
      (v) => /.+@.+/.test(v) || 'El correo electronico no es valido',
    ],
  }),
  components: {
    TablaParametros,
  },
  props: ['editar'],
  methods: {
    forceRerender() {
      const aux = this.componentKey + 1;
      this.componentKey = aux;
    },
    setUserId() {
      const aux = localStorage.getItem('user_id');
      this.userId = Number.parseInt(aux, 10);
    },
    getTipoParametro() {
      // revisar
      axios
        .get(`${API_URL}api/tipoParametros`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.parametrosMaestros = response.data.tipoParametros;
        });
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    resetForm() {
      this.$refs.form.reset();
    },
    presentarCategoriasOperatorias() {
      axios
        .get(`${API_URL}api/tipoServicios`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.tipoServicios = response.data.tipoServicios.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener los datos');
          }
        });
    },
    presentarServicios() {
      const tipo = this.tipo_servicio_id || 13;
      axios
        .get(`${API_URL}api/servicio/financiamiento/${tipo}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.operatorias = response.data.lineaCreditos.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener los datos');
          }
        });
      if (this.editar)
        this.operatoria_id = Number.parseInt(this.$route.params.id, 10);
    },
    presentarParametros() {
      this.forceRerender();
      axios
        .get(`${API_URL}api/tipoParametros/servicio/${this.operatoria_id}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.tipoParametros = response.data.tipoParametros.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener los datos');
          }
        });
    },
    getLineaCredito() {
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const url = `${API_URL}api/servicio/132`;
      axios
        .get(url, header)
        .then((result) => {
          this.operatoria_id = result.data.lineaCredito.fideicomiso_id.id;
          this.tipo_servicio_id = result.data.lineaCredito.tipo_servicio_id;
          this.presentarServicios();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getTipoParametro();
    this.presentarCategoriasOperatorias();
    this.setUserId();
    this.getLineaCredito();
    this.presentarServicios();
  },
};
</script>
