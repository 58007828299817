<template>
  <div>
    <v-alert v-if="success" border="bottom" color="success" dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error" border="bottom" color="red" dark>
      <b>Ocurrió un error al guardar el Documento.</b> {{ errorMensaje }}
    </v-alert>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="fideicomiso_id"
            :items="fideicomisos"
            @change="loadLineaCredito"
            filled
            :loading="load.fideicomiso"
            required
            label="Unidad de gestión"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            :items="lineaCreditos"
            v-model="lineaCredito_id"
            label="Operatoria"
            :loading="load.linea"
            @change="loadDocumentosMaestros"
            filled
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-alert
            v-if="fideicomisoSinLineasBandera"
            border="bottom"
            color="info"
            dark
          >
            La <b>Unidad de gestión</b> seleccionado no contiene Operatorias,
            los documentos a continuación se vincularan a la unidad de gestión
            en si.
          </v-alert>
          <v-card>
            <v-simple-table v-if="flag">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nombre</th>
                    <th class="text-left">Personería</th>
                    <th class="text-left">Descripcion</th>
                    <th class="text-left">Etapa</th>
                    <th class="text-left">Vencimiento</th>
                    <th class="pl-1">
                      <v-checkbox
                        label="Seleccionar Todos"
                        v-model="habilitarTodos"
                        @change="selectAll"
                        color="success"
                      >
                      </v-checkbox>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dm, index) in documentosMaestros" :key="index">
                    <td>
                      <v-text-field
                        v-model="tableData[index].nombre"
                        :rules="nameRules"
                        label="Nombre"
                        :disabled="!tableData[index].habilitado"
                        required
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        v-model="tableData[index].personeria"
                        label="Tipo"
                        :items="personeriaOptions"
                        :disabled="!tableData[index].habilitado"
                        required
                      ></v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableData[index].descripcion"
                        :rules="nameRules"
                        label="Descripcion"
                        :disabled="!tableData[index].habilitado"
                        required
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        v-model="tableData[index].etapa"
                        label="Etapa"
                        :items="[
                          { text: 'Precalificación', value: 1 },
                          { text: 'Calificación', value: 2 },
                          { text: 'Aprobado', value: 6 },
                        ]"
                        :disabled="!tableData[index].habilitado"
                        required
                      ></v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableData[index].vencimiento"
                        :rules="nameRules"
                        type="number"
                        :disabled="!tableData[index].habilitado"
                        label="Días"
                        required
                      ></v-text-field>
                    </td>
                    <td>
                      <v-row>
                        <v-checkbox
                          v-model="tableData[index].habilitado"
                          :input-value="tableData[index].habilitado"
                          color="success"
                          class="mr-20"
                        ></v-checkbox>
                        <v-tooltip v-if="tableData[index].previnculado" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-3" icon v-bind="attrs" v-on="on">
                              <v-icon color="primary"> mdi-information </v-icon>
                            </v-btn>
                          </template>
                          <span>Ya vinculado</span>
                        </v-tooltip>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-btn color="primary" x-large :loading="loading" @click="guardar()">
            <template v-slot:loader="{ size, value }">
              <v-progress-circular
                :size="size"
                :indeterminate="true"
                :value="value"
              /> </template
            >Guardar</v-btn
          >
        </v-col>
        <v-col md="2">
          <v-btn color="success" x-large @click="listado">Ver Listado</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

const _ = require('lodash');

export default {
  data: () => ({
    personeriaOptions: [
      { text: 'Ambas', value: 'ambas' },
      { text: 'Física', value: 'fisica' },
      { text: 'Jurídica', value: 'juridica' },
    ],
    tableData: [],
    fideicomiso_id: '',
    lineaCredito_id: '',
    habilitarTodos: false,
    fideicomisos: [],
    success: false,
    error: false,
    loading: false,
    successMensaje: false,
    errorMensaje: false,
    valid: false,
    lineaCreditos: [],
    flag: false,
    documentosMaestros: [],
    nameRules: [(v) => !!v || 'El campo es obligatorio'],
    load: {
      fideicomiso: false,
      linea: false,
      documentos: false,
    },
    fideicomisoSinLineasBandera: false,
    docsVinculados: [],
  }),
  methods: {
    getOptionIndex(options, value) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          return i;
        }
      }
      return -1; // Opción no encontrada
    },
    validate() {
      this.$refs.form.validate();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    selectAll() {
      if (this.habilitarTodos) {
        this.tableData.forEach((t, i) => {
          this.tableData[i].habilitado = true;
        });
      } else {
        this.tableData.forEach((t, i) => {
          this.tableData[i].habilitado = false;
        });
      }
    },

    async guardar() {
      this.loading = true;
      if (this.lineaCredito_id === '') {
        await this.$swal.fire(
          'Debe seleccionar una línea de crédito!!',
          '',
          'error'
        );
      }
      this.success = false;
      this.error = false;
      this.validate();

      let i = 0;
      let data = [];

      const url = `${API_URL}api/documentos/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };

      const dataTable = this.tableData.filter((data) => data.habilitado);
      data.push({ lineaCredito_id: this.lineaCredito_id });
      for (i = 0; i < dataTable.length; i += 1) {
        if (dataTable[i].vencimiento === '') {
          await this.$swal.fire({
            icon: 'error',
            title: `Debe ingresar los días de vencimiento!!`,
            showConfirmButton: false,
            timer: 2000,
          });
          this.loading = false;
          return;
        }
        data.push({
          fideicomiso_id: this.fideicomiso_id,
          nombre: dataTable[i].nombre,
          personeria:
            dataTable[i].personeria === '' ? 'ambas' : dataTable[i].personeria,
          descripcion: dataTable[i].descripcion,
          etapa: dataTable[i].etapa === '' ? 1 : dataTable[i].etapa,
          vencimiento: dataTable[i].vencimiento,
          id: dataTable[i].idDoc,
          idMaestro: dataTable[i].idMaestro,
        });
      }

      axios
        .post(url, data, header)
        .then(() => {
          this.loadDocumentosMaestros();
          this.success = true;
          this.successMensaje = 'Vinculación almacenada correctamente!';
        })
        .catch((error) => console.error(error));
    },
    async loadFideicomisos() {
      this.load.fideicomiso = true;
      const url = `${API_URL}api/admin/unidadGestion`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.fideicomisos = response.data.fideicomisos.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener fideicomisos');
          }
          this.load.fideicomiso = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    limpia() {
      this.documentosMaestros = [];
      this.tableData = [];
      this.lineaCreditos = [];
      this.lineaCredito_id = '';
    },
    async loadLineaCredito() {
      this.limpia();
      this.load.linea = true;
      const url = `${API_URL}api/servicio/unidadGestion/${this.fideicomiso_id}`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.lineaCreditos.length > 0) {
              this.lineaCreditos = response.data.lineaCreditos.map((f) => ({
                value: f.id,
                text: f.nombre,
              }));
              this.fideicomisoSinLineasBandera = false;
            } else {
              this.flag = true;
              this.fideicomisoSinLineasBandera = true;
              this.loadDocumentosMaestros();
            }
          } else {
            console.log(response.data.message);
          }
          this.load.linea = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadDocumentosMaestros() {
      this.loading = true;
      const url = `${API_URL}api/documentoMaestros`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.documentosMaestros = response.data.documentacionMaestros;
            this.tableData = response.data.documentacionMaestros.map((dm) => ({
              idMaestro: dm.id,
              nombre: dm.nombre,
              descripcion: dm.descripcion,
              vencimiento: '',
              etapa: '',
              habilitado: '',
              previnculado: false,
            }));
            this.flag = true;
            this.loadVinculados();
          } else {
            console.log('Error al obtener Maestro de documentos');
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async loadVinculados() {
      const url = `${API_URL}api/documentos/vinculados`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        tipoDocumento: '',
        lineaCredito_id: '',
        fideicomiso_id: '',
      };
      if (this.lineaCredito_id !== '') {
        data.lineaCredito_id = this.lineaCredito_id;
        data.tipoDocumento = 'lineaCredito';
      }
      if (this.fideicomiso_id !== '' && this.lineaCredito_id === '') {
        data.fideicomiso_id = this.fideicomiso_id;
        data.tipoDocumento = 'fideicomiso';
      }
      axios.post(url, data, header).then((response) => {
        if (typeof response.data.documentos !== 'undefined') {
          const documentos = response.data.documentos;

          documentos.forEach((element) => {
            this.tableData.forEach((t, i) => {
              if (
                t.nombre === element.nombre ||
                t.idMaestro === element.documentoMaestro_id
              ) {
                this.tableData[i].idDoc = element.id;
                this.tableData[i].etapa = element.etapa;
                this.tableData[i].vencimiento = element.vencimiento;
                this.tableData[i].habilitado =
                  element.status === true ? true : false;
                this.tableData[i].previnculado = true;
                const optionIndex = this.getOptionIndex(
                  this.personeriaOptions,
                  element.personeria
                );
                if (optionIndex !== -1) {
                  this.tableData[i].personeria =
                    this.personeriaOptions[optionIndex].value;
                } else {
                  this.tableData[i].personeria =
                    this.personeriaOptions[0].value;
                }
              }
            });
          });
        }
      });
    },
  },

  created() {
    this.loadFideicomisos();
  },
};
</script>
